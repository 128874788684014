<template>
  <div class="final-page flex-1">
    <div class="final-page__content">
      <h1>Поздравляем, тест завершен!</h1>
      <div class="final-page__text">
        <p>Благодарим, что выбрали нас.</p>
        <p
            v-if="getRight('downloadReports') &&
          test.status === 'finished' &&
          test.allow_view_report &&
          test.report_type !== 'empty'
        "
            class="test-block__btn"
        >
          <button
              @click="getLink"
              :class="`btn btn-accent ${!test.code && 'disabled'}`"
          >
            Получить отчет
          </button>
          <span v-if="showLink" class="redirect-link">
            <a :href="showLink" target="_blank">{{ showLink }}</a>
          </span>
        </p>
        <p v-else>
          Вы найдете результаты тестирования в своем личном кабинете. Если вы
          были приглашены к тестированию компанией, за результатами обратитесь к
          отправителю ссылки на прохождение теста.
        </p>
        <p>
          Вы сделали первый шаг на пути к тому, чтобы стать продвинутым
          пользователем эмоционального интеллекта. Не останавливайтесь на
          достигнутом – развивайте эмоциональный интеллект вместе с нами:
        </p>
        <div class="final-page__link-section">
          <p>Хотите узнать больше о работе эмоционального интеллекта?</p>
          Почитайте статьи в
          <a target="_blank" href="https://eiskills.kz/media-center">Медиацентре</a>
        </div>
        <div class="final-page__link-section">
          <p>Хотите определиться с решением для дальнейшего развития ЭИ?</p>
          Подберите решение в <a target="_blank" href="https://eiskills.kz/catalog">Каталоге</a>
        </div>
        <div class="final-page__link-section">
          <p>Хотите исследовать платформу EISKILLS?</p>
          Ознакомьтесь с <a target="_blank" href="https://eiskills.kz/">Главной страницей</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import useCheckRights from "@/mixins/useCheckRights";
import {ref} from "@vue/reactivity";

export default {
  name: "final-page",
  setup() {
    const store = useStore(),
        {getRight} = useCheckRights();
    const isPreloader = ref(false),
        showLink = ref(null),
        test = computed(() => store.state.test.currentPassing);

    const getLink = () => {
      isPreloader.value = true;
      store
          .dispatch("test/getReportLink", test.value.code)
          .then(({data}) => {
            window.open(data, "Отчет");
            showLink.value = data;
          })
          .finally(() => (isPreloader.value = false));
    };


    return {
      'test': test,
      'getRight': getRight,
      'getLink': getLink,
      'showLink': showLink
    }
  }
};
</script>

<style scoped lang="scss">
.final-page {
  text-align: left;
  padding: 50px 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;

  &__content {
    margin: -5% auto 0;
    padding: 50px;

    h1 {
      font-size: 50px;
    }

    p {
      margin-bottom: 25px;
      font-size: 18px;
    }
  }

  &__link-section {
    font-size: 18px;

    p {
      margin-bottom: 6px;
    }

    a {
      color: var(--main-color);
    }

    margin-bottom: 25px;
  }

  @media (max-width: 1036px) {
    &__content {
      padding: 20px;

      h1 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
</style>